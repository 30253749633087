import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { BASE_URL } from './global.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService  {

  private prefixPRM: string = BASE_URL + '/prm/';
  private prefixGlobal: string = BASE_URL + '/global/';

  public constructor(private http: HttpClient, private router: Router) {
  }

  public setToken(token: string) {
    localStorage.setItem('token', token);
  }

  public getToken() {
    let token = '';
    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token') ?? '{}';
    }
    return token;
  }

  public logout() {
    return this.http.get(this.prefixGlobal + 'logout');
  }

  public checkToken() {
    return this.http.get(this.prefixGlobal + 'checktoken');
  }

  public authenticate(user: any, role: string) {
    const body = new FormData();
    body.append('code', user.login);
    body.append('password', user.password);
    body.append('role', role);
    return this.http.post(this.prefixPRM + 'login', body);
  }

}
